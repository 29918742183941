.circle-container {
  position: relative;
  min-height: 600px;
  overflow: hidden;
}

.circle-wrapper {
  position: relative;
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Center Image */
.center-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center-img {
  height: 190px;
  border-radius: 50%;
}

/* Outer Circles */
.outer-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 340px;
  height: 340px;
  margin-left: -168px;
  margin-top: -168px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle1 {
  border: 1px solid #a3a33a;
  animation: rotate-circle1 20s  infinite;
}

.circle2 {
  border: 3px dashed #4caf50;
  width: 500px;
  height: 500px;
  margin-left: -251px;
  margin-top: -251px;
  animation: rotate-circle2 20s  infinite ; 
}

.service-img img {
  position: absolute;
  height: 75px;
  width: 75px;
  border: 1px solid rgb(102, 101, 101);
  border-radius: 50%;
}

/* Services around the circle */
.outer-circle .service {
  position: absolute;
  width: 180px;
  text-align: center;
  z-index: 100;
  padding: 10px;
  color: white;
}

.sub-service {
  position: absolute;
  width: 100px;
  text-align: center;
  background: #000;
  color: white;
  border-radius: 20px;
  padding: 2px;
}

/* Positioning services in Circle 1 */
.circle1 .service-img:nth-child(1) img {
  transform: translate(92%, 125%);
}
.circle1 .service-img:nth-child(2) img {
  transform: translate(-267%, -56%);
}
.circle1 .service-img:nth-child(3) img {
  transform: translate(50%, -243%);
}

/* Positioning services in Circle 2 */
.circle2 .service:nth-child(1) {
  transform: translate(-130%, -230%);
  background-color: #0197b2;
}
.circle2 .service:nth-child(2) {
  transform: translate(-106%, 310%);
  background-color: #d56048;
}
.circle2 .service:nth-child(3) {
  transform: translate(44%, 571%);
  background-color: #f1a256;
}
.circle2 .service:nth-child(4) {
  transform: translate(120%, 10%);
  background-color: #5070fc;
}
.circle2 .service:nth-child(5) {
  transform: translate(41%, -528%);
  background-color: #4caf50;
}
.circle2 .sub-service-first-child {
  transform: translate(-67%, -607%);
}
.circle2 .sub-service-2nd-child {
  transform: translate(212%, -494%);
}
.circle2 .sub-service-3rd-child {
  transform: translate(212%, -294%);
}

/* Animations */
@keyframes rotate-circle1 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes rotate-circle2 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes counter-rotate-s-1-img-1 {
  0% {
    transform: translate(92%, 125%) rotate(0deg);
  }
  50% {
    transform: translate(92%, 115%) rotate(-45deg);
  }
  100% {
    transform: translate(92%, 125%) rotate(-0deg);
  }
}
@keyframes counter-rotate-s-1-img-2 {
  0% {
    transform: translate(-267%, -56%) rotate(0deg);
  }
  50% {
    transform: translate(-267%, -56%) rotate(-45deg);
  }
  100% {
    transform: translate(-267%, -56%) rotate(-0deg);
  }
}
@keyframes counter-rotate-s-1-img-3 {
  0% {
    transform: translate(50%, -243%) rotate(0deg);
  }
  50% {
    transform: translate(50%, -243%) rotate(-45deg);
  }
  100% {
    transform: translate(50%, -243%) rotate(-0deg);
  }
}
@keyframes counter-rotate-s-2-1 {
  0% {
    transform: translate(-130%, -230%) rotate(0deg);
  }
  50% {
    transform: translate(-130%, -128%) rotate(-45deg);
  }
  100% {
    transform: translate(-130%, -230%) rotate(-0deg);
  }
}
@keyframes counter-rotate-s-2-2 {
  0% {
    transform: translate(-106%, 310%) rotate(0deg);
  }
  50% {
    transform: translate(-106%, 310%) rotate(-45deg);
  }
  100% {
    transform: translate(-106%, 310%) rotate(-0deg);
  }
}
@keyframes counter-rotate-s-2-3 {
  0% {
    transform: translate(44%, 571%) rotate(0deg);
  }
  50% {
    transform: translate(44%, 571%) rotate(-45deg);
  }
  100% {
    transform: translate(44%, 571%) rotate(-0deg);
  }
}
@keyframes counter-rotate-s-2-4 {
  0% {
    transform: translate(120%, 10%) rotate(0deg);
  }
  50% {
    transform: translate(120%, 10%) rotate(-45deg);
  }
  100% {
    transform: translate(120%, 10%) rotate(-0deg);
  }
}
@keyframes counter-rotate-s-2-5 {
  0% {
    transform: translate(41%, -528%) rotate(0deg);
  }
  50% {
    transform: translate(41%, -528%) rotate(-45deg);
  }
  100% {
    transform: translate(41%, -528%) rotate(-0deg);
  }
}

/* service circle content animation */

.service-img:nth-child(1) img {
  animation: counter-rotate-s-1-img-1 20s infinite;
}
.service-img:nth-child(2) img {
  animation: counter-rotate-s-1-img-2 20s infinite;
}
.service-img:nth-child(3) img {
  animation: counter-rotate-s-1-img-3 20s infinite;
}

/* service 2 circle content animation */
.circle2 .service:nth-child(1) {
  animation: counter-rotate-s-2-1 20s infinite;
}
.circle2 .service:nth-child(2) {
  animation: counter-rotate-s-2-2 20s infinite;
}
.circle2 .service:nth-child(3) {
  animation: counter-rotate-s-2-3 20s infinite;
}
.circle2 .service:nth-child(4) {
  animation: counter-rotate-s-2-4 20s infinite;
}
.circle2 .service:nth-child(5) {
  animation: counter-rotate-s-2-5 20s infinite;
}



@media (max-width: 680px) {
  .circle-container{
    min-height: 463px;
  }
  .circle-wrapper {
    width: 220px;
    height: 220px;
  }

  .center-img {
    height: 120px;
  }

  .outer-circle {
    width: 180px;
    height: 180px;
    margin-left: -90px;
    margin-top: -90px;
  }

  .circle2 {
    width: 300px;
    height: 300px;
    margin-left: -150px;
    margin-top: -150px;
  }

  .service-img img {
    height: 50px;
    width: 50px;
  }

  .sub-service {
    /* width: 60px; */
    display: none;
  }
  .outer-circle .service {
    position: absolute;
    width: 100px;
    font-size: 10px;
    text-align: center;
    z-index: 100;
    padding: 7px;
    color: white;
  }

  /* Positioning services in Circle 1 */
  .circle1 .service-img:nth-child(1) img {
  transform: translate(55%, 98%);
 }
  .circle1 .service-img:nth-child(2) img {
  transform: translate(-228%, -54%);
}
 .circle1 .service-img:nth-child(3) img {
  transform: translate(50%, -195%);
}

  .circle2 .service:nth-child(1) {
    transform: translate(-103%, -190%);
    background-color: #0197b2;
  }
  .circle2 .service:nth-child(3) {
    transform: translate(44%, 498%);
    background-color: #f1a256;
  }
  .circle2 .service:nth-child(4) {
    transform: translate(116%, 26%);
    background-color: #5070fc;
  }
  .circle2 .service:nth-child(5) {
    transform: translate(60%, -280%);
    background-color: #4caf50;
  }

  @keyframes counter-rotate-s-1-img-1 {
    0% {
      transform:translate(55%, 98%) rotate(0deg);
    }
    50% {
      transform:translate(55%, 98%) rotate(-45deg);
    }
    100% {
      transform:translate(55%, 98%) rotate(-0deg);
    }
  }
  @keyframes counter-rotate-s-1-img-2 {
    0% {
      transform: translate(-228%, -54%) rotate(0deg);
    }
    50% {
      transform: translate(-228%, -54%) rotate(-45deg);
    }
    100% {
      transform: translate(-228%, -54%) rotate(-0deg);
    }
  }
  @keyframes counter-rotate-s-1-img-3 {
    0% {
      transform: translate(50%, -195%) rotate(0deg);
    }
    50% {
      transform: translate(50%, -195%) rotate(-45deg);
    }
    100% {
      transform: translate(50%, -195%) rotate(-0deg);
    }
  }

  @keyframes counter-rotate-s-2-1 {
    0% {
      transform: translate(-103%, -190%) rotate(0deg);
    }
    50% {
      transform: translate(-103%, -190%) rotate(-45deg);
    }
    100% {
      transform: translate(-103%, -190%) rotate(-0deg);
    }
  }
  @keyframes counter-rotate-s-2-3 {
    0% {
      transform: translate(44%, 498%) rotate(0deg);
    }
    50% {
      transform: translate(44%, 498%) rotate(-45deg);
    }
    100% {
      transform: translate(44%, 498%) rotate(-0deg);
    }
  }
  @keyframes counter-rotate-s-2-4 {
    0% {
      transform: translate(116%, 26%) rotate(0deg);
    }
    50% {
      transform: translate(116%, 26%) rotate(-45deg);
    }
    100% {
      transform: translate(116%, 26%) rotate(-0deg);
    }
  }
  @keyframes counter-rotate-s-2-5 {
    0% {
      transform: translate(60%, -280%) rotate(0deg);
    }
    50% {
      transform: translate(60%, -280%) rotate(-45deg);
    }
    100% {
      transform: translate(60%, -280%) rotate(-0deg);
    }
  }
  
}
