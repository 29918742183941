.d-none {
    display: none;
  }
  
  body {
    background: #f9f8f8;
    font-size: 14px;
    font-family: "Poppins", sans-serif !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  button,
  input,
  textarea {
    font-family: "Poppins", sans-serif !important;
  }
  .center-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 85vh;
  }
  
  p {
    font-size: 14px;
    font-family: "Poppins", sans-serif !important;
  }
  a {
    /* color: inherit; */
    text-decoration: none;
    cursor: pointer;
    font-family: "Poppins", sans-serif !important;
  }
  a:hover {
    /* color: inherit; */
    text-decoration: none;
    cursor: pointer;
  }
  
  .swal2-styled:focus {
    box-shadow: none !important;
  }
  
  
  
  /* Example CSS */
  .flex-container1 {
      display: flex;
      justify-content: space-around;
      align-items: stretch; /* Adjust as needed */
      padding: 20px;
      gap: 5px;
      background-color: #d4d4d4;
    }
    
    
    .flex-item1 {
      flex: 1;
      text-align: center;
      border: 1px solid #ccc;
      padding: 10px;
      margin: 5px;
      box-sizing: border-box; 
      border-style: solid;
      border-color: #ffffff;
      color: whitesmoke;
      border-radius: 10px;
      transition: border-color 0.3s, transform 0.2s;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); 
    }
    .flex-item1:hover {
      border-color: green; 
      transform: scale(1.03);
    }
    
  
    @media screen and (max-width: 768px) {
      .flex-container1 {
        flex-direction: column; /* Stack cards vertically on small screens */
      }
    
      .flex-item1 {
        margin-bottom: 20px; /* Add spacing between cards on small screens */
      }
    }
  
  /* VAT Calulator*/
  .calculator-container {
    width: 96%;
    margin: 50px auto;
    padding: 20px;
    border: 2px solid #4caf50;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    background: linear-gradient(135deg, #2196f3, #4caf50); /* Gradient background */
    color: #fff; /* Text color */
  }
  
  label {
    margin-bottom: 8px;
    display: block;
  }
  
  input {
    width: 100%;
    padding: 10px;
    margin-bottom: 16px;
    border: 1px solid #fff;
    color: #333; /* Input text color */
    background-color: rgba(255, 255, 255, 0.8); /* Input background color with opacity */
    border-radius: 4px;
  }
  
  button-container {
    display: flex;
    justify-content: space-between;
  }
  
  button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  .reset-button {
    background-color: #ff4081;
  }
  
  .reset-button:hover {
    background-color: #d81b60;
  }
  
  .result {
    margin-top: 20px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid #99c2ff;
    border-radius: 4px;
    color: #333; /* Result text color */
  }
  .calculator-container > h1{
    text-align: center;
  }
  
  /* Contact form*/
  
  .custom-form-container {
    width: 100%;
    margin: 50px auto;
    padding: 20px;
    border: 2px solid #4caf50;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    background: linear-gradient(135deg, #2196f3, #4caf50);
    color: #fff;
  }
  
  label {
    margin-bottom: 8px;
    display: block;
  }
  select {
    margin-bottom: 8px;
    display: block;
    padding:8px;
  }
  
  input {
    width: 100%;
    padding: 10px;
    margin-bottom: 16px;
    border: 1px solid #fff;
    color: #333;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
  }
  
  button-container {
    display: flex;
    justify-content: space-between;
  }
  
  button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  .reset-button {
    background-color: #ff4081;
  }
  
  .reset-button:hover {
    background-color: #d81b60;
  }
  
  .result {
    margin-top: 20px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid #99c2ff;
    border-radius: 4px;
    color: #333;
  }
  
  .custom-form-container > h2 {
    text-align: center;
  }
  
  .bkash-button {
    background-color: #e3106e;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .responsive-img
  {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  
  .form-submit-button{
    border: 1px solid;
    border-radius: 10px;
    font-size: 18px;
    background-color: var(--personal);
  }
  .form-submit-button:hover {
    background-color: var(--buttonhover); /* Change background color on hover */
    border-color: var(--buttonhover); /* Change outline color on hover */
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2); /* Change box shadow on hover */
  }
  .form-submit-outline-button{
    border: 1px solid var(--personal);
    border-radius: 10px;
    color: var(--personal);
    font-size: 18px;
    background-color: #ffffff;
  }
  .form-submit-outline-button:hover{
    border: 1px solid var(--personal);
    border-radius: 10px;
    font-size: 18px;
    background-color: var(--personal);
    color: white;
  }
  
  /*header part water float*/
  @keyframes floatUp {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .float-up {
    animation: floatUp 3s ease infinite;
  }
  
  /* Add more styles as needed */
  
  /*blog card start*/
  /* src/BlogCard.css */
  .blog-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .blog-card {
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
    overflow: hidden;
    width: calc(33.333% - 40px);
    transition: transform 0.3s ease;
  }
  
  /* Hover effect */
  .blog-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    .blog-card {
      width: calc(50% - 40px);
    }
  }
  
  @media screen and (max-width: 480px) {
    .blog-card {
      width: calc(100% - 40px);
    }
  }
  .blog-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .blog-card h2 {
    margin: 10px 0;
  }
  
  .blog-card p {
    color: #333;
  }
  
  .blog-info {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 0.9rem;
    color: #666;
  }
  
  /*blog card end*/
  
  /*property consultancy*/
  .property-consultancy-page {
    padding: 20px;
  }
  
  .section {
    margin-bottom: 40px;
  }
  
  .services-section, .properties-section {
    text-align: center;
    margin-top: 70px;
  }
  
  .service-list, .property-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .service, .property {
    width: 300px;
    margin: 0 20px 20px 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: transform 0.3s ease;
  }
  
  .service:hover, .property:hover {
    transform: translateY(-5px);
  }
  
  .property img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .property-info h3 {
    margin-bottom: 5px;
  }
  
  @media screen and (max-width: 768px) {
    .service, .property {
      width: calc(50% - 40px);
    }
  }
  
  @media screen and (max-width: 480px) {
    .service, .property {
      width: calc(100% - 40px);
    }
  }
  
  
  /*VisaConsultancyPage*/
  .visa-consultancy-page {
    padding: 20px;
  }
  
  .section {
   
    margin-top: 100px;
  }
  
  .services-section {
    text-align: center;
  }
  
  .service-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;
  }
  
  .service img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .service {
    width: 300px;
    margin: 0 20px 20px 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: transform 0.3s ease;
  }
  
  .service:hover {
    transform: translateY(-5px);
  }
  
  .service h3 {
    margin-bottom: 10px;
  }
  
  @media screen and (max-width: 768px) {
    .service {
      width: calc(50% - 40px);
    }
  }
  
  @media screen and (max-width: 480px) {
    .service {
      width: calc(100% - 40px);
    }
  }
  #image-container {
    width: 100%;
    max-width: 1500px; /* Adjust as needed */
    max-height: 900px;
  }
  
  #resizable-image {
    width: 100%;
    height: auto;
    display: block;
  }
  
  /* Chat Bot Container */
  .chatbot-container {
    position: relative;
    font-family: Arial, sans-serif;
  }
  
  /* Chat Box */
  .chatbot-chat-window {
    position: fixed;
    bottom: 150px;
    right: 20px;
    max-width: 300px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
  }
  
  /* Close Button */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  /* Chat Header */
  .chatbot-header {
    background-color: var(--personal);
    color: #fff;
    padding: 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  /* Chat Messages */
  .chat-messages {
    max-height: 300px;
    overflow-y: auto;
    padding: 15px;
  }
  
  /* Individual Messages */
  .incoming-message {
    color: black;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  /* Message Input */
  .message-input {
    padding: 15px;
  }
  
  /* Close Button Icon */
  .close-button svg {
    width: 20px;
    height: 20px;
  }
  
  .marquee-container {
    background-color: #cdeed5; /* Background color */
    width: 10%; /* Full width */
    padding: 15px;
  }
  
  .home-top {
    border-radius: 10px;
    color: rgb(255, 255, 62); /* Desired hover color */
  }
  
  .home-top:hover {
    color: rgb(255, 255, 255); /* Desired hover color */
  }
  
  
  
  
  .dropdown-11 {
    border: 2px solid #4CAF50;
    padding-left: 10px;
    border-radius: 15px 0 0 15px;
  }
  
  
  /* style.css */
  
  /* Center the image and set its initial size */
  .centered-img {
    animation-duration: 3s;
    width: 65%;
    display: block;
    margin: auto;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .photo-frame {
      margin: 0 !important;
      width: 100%; /* Make the image full width on smaller screens */
      margin-top: 0px;
    }
  }
  
  /* Ensure parent container aligns text to center */
  .text-center {
    text-align: center;
  }
  /* #16D5FF */
:root {
    --buttonhover: #FF5733;
    --personal: #4CAF50;
    --primary:  #008080; 
    --secondary: #999999;
    --light: #F2F8FE;
    --dark: #111111;
}
body {
  font-family: 'Roboto', 'Lato', sans-serif;
}


/* startup start */

.card-title {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  color:#797777;
  font-size: 1.25rem;
}
.card-text{
  background-color: #f9f9f9;
  color: black;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.card-text1{
  color: white;
  font-size: 10px;
  text-align: end;
}

.flex-item12 {
  flex: 1;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 5px;
  box-sizing: border-box; 
  border-style: solid;
  border-color: #ffffff;
  color: whitesmoke;
  border-radius: 10px;
  transition: border-color 0.3s, transform 0.2s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); 
  background-color: #16A085;
  position: relative;
}
.flex-item12:hover {
  border-color: green; 
  transform: scale(1.03);
}
.getqot {
  position: absolute;
  bottom: 10px; /* Adjust the distance from the bottom as needed */
  left: 30%;
  transform: translateX(-50%);
}

.flex-container12 {
  display: flex;
  justify-content: space-around;
  align-items: stretch; /* Adjust as needed */
  padding: 20px;
  gap: 5px;
}

.button-container {
  display: flex;
  justify-content: center;
  margin: 0 auto; /* Aligns the container in the middle */
  max-width: 400px; /* Set maximum width */
}

.button12 {
  flex: 1;
  margin-right: 5px; /* Adjust the gap between buttons */
  padding: 5px 30px; /* Adjust padding to increase button size */
  border: 2px solid;
  border-radius: 5px;
  border-color: #04aa6d;
  font-size: 16px;
  font-weight: 30px;
  cursor: pointer;
  color: black; /* Set text color to black */
  background-color: white;
  transition: background-color 0.3s ease;
}

.button12:hover {
  background-color: #04aa6d;
  color: white;
}

.button12.active {
  background-color: #04aa6d;
  color: white;
  font-weight: 20px;
  /* Add any other styles for the hover effect */
}
/* hero section css */
heroSection{
  padding: 6em 0em 6em 0em;
  overflow: hidden;
  background-size: cover;
  background-position:center;
}
.r-container {
  max-width: 95%;
  margin-right: auto;
  margin-left: auto;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}
.font-2 {
  font-family: var(--font-2);
}
.font-1 {
  font-family: var(--font-1);
  font-size: 5rem;
}
.font-3 {
  font-family: var(--font-1);
  font-size: 3rem;
}
.fw-bold {
  font-weight: 700!important;
}
.heroImg {
  object-fit: cover;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  box-shadow: 40px -40px 0px -4px #4caf50 ,40px -40px 0px -4px #4caf50, -54px 44px 0px -3px #f35120;
  border-radius: 2rem;
}
.aboutImg {
  object-fit: cover;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  box-shadow:-54px 44px 0px -3px #f35120;
  border-radius: 2rem;
}
.one-rem-text{
 font-size: 1rem;
}
.four-rem-text{
 font-size: 4rem;
}

/* Media query for smaller screen sizes */
@media screen and (max-width: 600px) {
  .button-container {
    max-width: 300px; /* Adjust maximum width for smaller screens */
  }

  .button12 {
    padding: 5px 10px; /* Decrease padding for smaller screens */
  }
}


/* To remove the right margin from the last button */
.button12:last-child {
  margin-right: 0;
}

@media screen and (max-width: 768px) {
  .flex-container12 {
    flex-direction: column; /* Stack cards vertically on small screens */
  }

  .flex-item12 {
    margin-bottom: 20px; /* Add spacing between cards on small screens */
  }
}

/* startup end */
.heading {
  color: #0e5474;
  font-size: x-large;
  font-weight: 600;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.back-to-top {
    position: fixed;
    display: none;
    right: 30px;
    bottom: 20px;
    z-index: 99;
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
    font-weight: 500 !important;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.homecardBoxShadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.6);
}

/*** Spinner start***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}
/*** Spinner end***/

/*** Button ***/
.btn {
    font-weight: 500;
    transition: .5s;
}

.button-2{
  padding-left: 20px;
  padding-right: 20px;
}

.btn.btn-primary {
  color: #FFFFFF;
  background-color: var(--personal);
  border: 2px solid #FFFFFF; /* Outline color */
  border-radius: 25px; /* Rounded corners */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow */
  transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

.btn.btn-primary:hover {
  background-color: var(--buttonhover); /* Change background color on hover */
  border-color: var(--buttonhover); /* Change outline color on hover */
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2); /* Change box shadow on hover */
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
}

.breadcrumb-item a {
  color: #4CAF50;
}

.heading-color {
  color: #4CAF50;
}

.color-text {
  color: var(--personal) !important;
}


header {
  position: sticky;
  top: 0;
  /* top: 0; left: 0; right: 0; */
  background: rgb(247, 247, 247);
  box-sizing: 0 5px 10px rgba(0,0,0,.1);
  padding: 0px 7%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3px;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 
}

header .navbar-1 ul {
  list-style: none;
  margin: 0;
  padding: 5px;
}

header .navbar-1 ul li {
  position: relative;
  float: left;
}

.nav-link-1 {
  font-size: 15px;
  padding: 8px;
  display: block;
  color: black;
  font-weight: 500;
  text-decoration: none;
}

.nav-link-1:hover {
  color: var(--personal);
}

.element {
  color: rgb(32, 32, 32);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  transition: color 0.3s ease, letter-spacing 0.3s ease; /* Apply transition to color and letter-spacing */
}

.element:hover {
  color: var(--personal);
  letter-spacing: 1px; /* Change the letter-spacing when hovering */
}

header .navbar-1 ul li ul {
  position: absolute;
  left: 0;
  right: 0;
  width: 200px;
  font-size: 14px;
  background: white;
  display: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add box shadow */
  transition: all 0.9s ease;
}

.navbar-1 ul li ul::after {
  border: none;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
  transition: all 0.9s ease;
}

header .navbar-1 ul li ul li {
  width: 100%;
  padding-top: 3px;
  border-top: 1px solid rgba(214, 214, 214, 0.1);
}

header .navbar-1 ul li ul li ul {
  top: 27px;
  transition: all 0.9s ease;
}

/* header .navbar-1 ul li:focus-within > ul, */
header .navbar-1 ul li:hover > ul {
  display: initial;
  
}
.audit-menu ul li :hover{
  display: initial;
}

#menu-bar {
  display: none;
}

header label {
  font-size: 20px;
  cursor: pointer;
  display: none;
}

.searchform {
  margin-left: 0px; /* Adjust margin as needed for spacing */
  margin-bottom: 38px;
}

@media (max-width: 991px) {
  header {
    padding: 10px;
  }

  header label {
    display: initial;
  }

  header .navbar-1 {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border-top: 1px solid rgba(243, 243, 243, 0.1);
    display: none;
    margin-left: 0px;
  }

  header .navbar-1 ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  header .navbar-1 ul li {
    width: 100%;
    text-align: left; /* Align the text to the left */
    margin-top: 0; /* Add margin from the top */
  }

  header .navbar-1 ul li ul {
    position: relative;
    width: 100%;
    left: 30px;
    top: 0;
  }

  header .navbar-1 ul li ul li {
    background: white;
  }

  header .navbar-1 ul li ul li ul {
    width: 100%;
    left: 30px;
    top: 0;
  }

  #menu-bar:checked + label + .navbar-1 {
    display: initial;
  }

  .searchform {
    margin-left: 20px;
    margin-bottom: 35px; /* Remove margin-bottom to align with the navigation bar */
    z-index: 1000; /* Adjust the z-index based on your needs */
  }
}

/* Style for the dropdown container end*/

/*** Header start***/
.hero-header .breadcrumb-item+.breadcrumb-item::before {
    color: var(--secondary);
}

/*** Header end***/

/*** Footer start***/
.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: var(--secondary);
    font-weight: normal;
    font-size:13.5px;
    transition: .3s;
}

.footer .btn.btn-link-1 {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: var(--secondary);
  font-weight: normal;
  font-size:13.5px;
  transition: .3s;
}
.footer .btn.btn-link-1:hover {
  color: var(--personal);
  letter-spacing: 1px;
  box-shadow: none;
}
.footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: var(--secondary);
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    color: var(--personal);
    letter-spacing: 1px;
    box-shadow: none;
}

.footer .btn.btn-square {
    color: var(--personal);
    border: 1px solid var(--personal);
}

.footer .btn.btn-square:hover {
    color: #FFFFFF;
    background: var(--personal);
}

.footer .copyright {
    padding: 25px 0;
    border-top: 1px solid rgba(17, 17, 17, .1);
}

.footer .copyright a {
    color: var(--personal);
}

.footer .copyright a:hover {
    color: var(--dark);
}

/*** Footer end***/

.container-xxl p {
    color: #5c5a5a; /* Dark black color */
    text-align: justify; 
}
.responsive-img {
    max-width: 65%;
    height: auto;
  }
.accordion-button:hover {
  background-color: var(--personal);
  color: white;
}
h1 {
    margin-bottom: 40px;
}

label {
    color: #333;
}

.btn-send {
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    width: 100%;
    
    }
.help-block.with-errors {
    color: #ff5050;
    margin-top: 5px;

}

.card{
	margin-left: 10px;
	margin-right: 10px;
}

.suggestion-item {
    padding: 4px; /* Add padding to the suggestion items */
    cursor: pointer;
    background-color: #0f4b58  ;
  }
  .react-autosuggest__suggestions-list {
    list-style-type: none;
  }
  .whatsapp-btn:hover {
    display: inline-block;
    background-color: #04aa6d;
  }

.container {
    max-width: 1300px;
    margin-inline: auto;
    padding-inline: var(--padding-inline-section);
    margin-top: 20px;
}
.about-page{
    background: linear-gradient(to bottom, #88bbf2 0%, #c7f5ee 100%);
}

.about-page > p{
    color: black;
    padding: 20px 20px;
    text-align: justify;
}

.about-page > h2 {
    padding-top: 20px;
    text-align: center;
}

/*about section flex start*/
.flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    gap: 12px;
  }
  
  .flex-item {
    flex: 1;
    text-align: center;
    border: 1px solid #ccc;
    padding: 10px;
    margin: 5px;
    box-sizing: border-box; /* Ensure padding and border are included in the width */
    border-style: solid;
    border-color: #ffffff;
    color: whitesmoke;
    border-radius: 10px;
    transition: border-color 0.3s, transform 0.2s;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add a subtle box shadow */
  }
  .flex-item:hover {
    border-color: green; /* Change to your desired border color on hover */
    transform: scale(1.03);
  }
   
  p {
    margin: 10px 0;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .flex-item {
      flex: 1 0 48%; /* Two items per row */
    }
  }
  
  @media (max-width: 576px) {
    .flex-item {
      flex: 1 0 100%; /* One item per row */
    }
  }
/*end*/

.flex{
    display: flex;
    justify-content: center;
    justify-content: space-between;
    gap: 40px;
    padding: 40px;
    width: 1000px;
}

.flex div {
    text-align: center; 
}

.flex p {
    margin: 0;
    font-weight: 700; 
    color: rgb(3, 3, 141);
    font-size: x-large;
}
.flex svg {
    font-size: 4em; /* Adjust the size as needed */
    display: inline-block;
    width: 80px; /* Adjust the width and height for the desired circle size */
    height: 80px;
    border-radius: 50%;
    background-color: rgb(3, 3, 141); /* Your desired color */
    color: white;
    
}

.slidep{
    color: black;
    border: 1px solid black; /* Specify the border width and style */
    border-radius: 2px;
    padding: 10px;
    
}

.slidepb{
    font-weight: 700;
}

.about-page-down{
    background-color: #ededaa;
    padding: 20px 20px;
    color: black;
    
}

.container2 {
    max-width: 1500px;
    margin-inline: auto;
    margin-top: 20px;
}

/* HomeSlideShare.css */
.slick-dots li button {
    color: blue;
    font-size: 30px; /* Adjust the font size to make the dots larger */
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 30px;
    row-gap: 30px;
    width: 100%;
  }


/* Add this to your CSS file */
.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  
  @media (max-width: 768px) {
    .grid-container {
      grid-template-columns:1fr;
    }
  }
  
  @media (max-width: 480px) {
    .grid-container {
      grid-template-columns: 1fr;
    }
  }
  
  .custom-button {
    padding: 10px 15px;
    background-color: #65c5c4;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .custom-button:hover {
    background-color: #408ab4; /* Change the color on hover if desired */
  }
  
  .custom-button:active {
    transform: scale(0.95); /* Add a slight scale effect on click */
  }

  .center-background {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1e73be; /* Change the background color as needed */
    margin-top: 20px;
    border-radius: 15px;
    
  }

  .center-background> h2 {
    color: white; /* Change the text color if necessary */
    padding: 25px;
    margin-top: 10px;
  }

  .center-background1 {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1e73be; /* Change the background color as needed */
    margin-top: 20px;
    border-radius: 15px;
    
  }

  .center-background1 > h2 {
    color: white; /* Change the text color if necessary */
    padding: 10px;
    margin-top: 10px;
  }

/* ImageGallery.css */

.image-gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 20px auto; /* Adjust margins for top and bottom */
    gap: 50px; /* Add row gap between images */
    text-align: center; /* Center the images horizontally */
  }
  
  .gallery-image {
    width: 100%;
    max-width: 300px; /* Adjust the maximum width of each image */
    height: auto;
    margin: 10px;
    border-radius: 10px; /* Add rounded corners if desired */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .gallery-image:hover {
    transform: scale(1.05); /* Add a slight scale effect on hover */
  }
  .text-xxl {
    font-size: 5rem;
  }
  .text-xl {
    font-size: 3rem;
  }

  .d-none{
    display: none;
  }
  
  
  /* responsive adjustment for home page */
  @media screen and (max-width: 768px) {
    .r-container {
      width: 90%;
      margin: auto;
    }
    .m-margin-top-1{
      margin-top: 1.125em;
    }
    .m-margin-top-half{
      margin-top: 15px;
    }
    .m-d-none {
      display: none;
    }
    .m-text-xxl {
      font-size: 4rem;
    }
    .m-text-xl {
      font-size: 2.625em;
    }
    .m-text-lg {
      font-size: 1.9rem;
    }
    .m-text-md {
      font-size: 1.5rem;
    }
    .m-text-sm {
      font-size: 1.125em;
    }
    .m-text-center{
      text-align: center;
    }
    .m-about-us-img{
      box-shadow: -26px 26px 0px -3px #f35120;
    }
    .m-margin-auto{
      margin: auto;
    }
    .m-widht {
      width: 90%;
    }
    .m-flex-wrap{
      flex-wrap: wrap;
    }
    .m-gap{
      gap:10px
    }
    .m-d-block {
      display: block;
    }
   
  }
  .fade-up {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeUp 1s ease-out forwards;
    /* Delay to simulate viewport entry (purely for demonstration) */
    animation-delay: 1s; 
}

@keyframes fadeUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}