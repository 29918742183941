/* The actual timeline (the vertical ruler) */
.main-timeline {
    position: relative;
  }
  
  /* The actual timeline (the vertical ruler) */
  .main-timeline::after {
    content: "";
    position: absolute;
    width: 6px;
    background-color: #939597;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
  }
  
  /* Container around content */
  .timeline {
    position: relative;
    background-color: inherit;
    width: 50%;
  }
  
  /* The circles on the timeline */
  .timeline::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    right: -13px;
    background-color: #939597;
    border: 5px solid #f5df4d;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
  }
  
  /* Place the container to the left */
  .left {
    padding: 0px 40px 20px 0px;
    left: 0;
  }
  
  /* Place the container to the right */
  .right {
    padding: 0px 0px 20px 40px;
    left: 50%;
  }
  
  /* Add arrows to the left container (pointing right) */
  .left::before {
    content: " ";
    position: absolute;
    top: 18px;
    z-index: 1;
    right: 30px;
    border: medium solid white;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent white;
  }
  
  /* Add arrows to the right container (pointing left) */
  .right::before {
    content: " ";
    position: absolute;
    top: 18px;
    z-index: 1;
    left: 30px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }
  
  /* Fix the circle for containers on the right side */
  .right::after {
    left: -12px;
  }
  
  /* Media queries - Responsive timeline on screens less than 600px wide */
  @media screen and (max-width: 600px) {
    /* Place the timelime to the left */
    .main-timeline::after {
      left: 31px;
    }
  
    /* Full-width containers */
    .timeline {
      width: 100%;
      padding-left: 70px;
      padding-right: 25px;
    }
  
    /* Make sure that all arrows are pointing leftwards */
    .timeline::before {
      left: 60px;
      border: medium solid white;
      border-width: 10px 10px 10px 0;
      border-color: transparent white transparent transparent;
    }
  
    /* Make sure all circles are at the same spot */
    .left::after,
    .right::after {
      left: 18px;
    }
  
    .left::before {
      right: auto;
    }
  
    /* Make all right containers behave like the left ones */
    .right {
      left: 0%;
    }
  }

  .resource-container {
    margin: 150px 0;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    text-align: center;
  }

   .resource-section-title {
      font-size: 28px;
      margin-bottom: 30px;
      text-align: start;
    }
    .resource-card-container {
      width: fit-content;
      display: flex;
      justify-content: space-between;
      gap: 20px;
      flex-wrap: wrap;
    }
    .resource-card {
      flex: 1;
      min-width: 215px;
      background: #fff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      text-align: center;
      transition: transform 0.3s ease;
    }
    .resource-card:hover {
      transform: translateY(-10px);
    }
    .resource-icon-circle {
      width: 70px;
      height: 70px;
      background-color: #e9f5fc;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 15px;
    }
    .resource-icon {
      font-size: 30px;
      color: #007bff;
    }
    .resource-card h4 {
      font-size: 18px;
      margin: 15px 0;
    }
    .resource-card p {
      font-size: 14px;
      color: #666;
    }
    .bg-purple {
      background-color: #7b2fb5; /* Purple background */
    }
    
    .custom-card {
      background-color: white;
      border-radius: 15px;
      padding: 20px;
      width: 300px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    
    .icon {
      width: 80px;
      height: 80px;
      background-color: #d6a6ff; /* Light purple */
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .icon-img {
      width: 50%;
      height: 50%;
    }
    
    .custom-title {
      font-size: 24px;
      color: #7b2fb5;
    }
    
    .custom-card p {
      font-size: 14px;
      line-height: 1.6;
      color: #444;
    }